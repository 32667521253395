<template>
  <div>

    <div class="main">
      <div class="title">
        <span @click="goPage('/home')">首页</span> > <span @click="goPage('/news')">新闻中心</span> > 新闻详情
      </div>
      <div class="msgs">
        <span class="titles">{{data.title}}</span>
        <span class="time">发布日期  {{data.createTime}}</span>
        <span class="text" v-html="data.content"></span>
      </div>
    </div>
    <div class="foot">
      <span
        >Copyright © 上海仁信保险经纪有限公司 版权所有 <a href="https://beian.miit.gov.cn" style="color: #B1A9A9;">沪ICP备12012639号-9 </a>
        </span
      >
    </div>
  </div>
</template>

<script>
import {articlePage} from '@/api/home.js'
export default {
  data () {
    return {
      data: {}
    }
  },
  created () {
    this.refresh()
  },
  methods: {
    refresh () {
      articlePage(this.$route.query.id).then(res => {
        console.log(res)
        this.data = res.data
        const content = res.data.content.replace(/src="/g, `src="/official`)
        this.data.content = content
        console.log(process.env.VUE_APP_BASE_API)
      })
    },
    goPage(url) {
      this.$router.push({path: url})
    },
  }
}
</script>

<style lang="less" scoped>
@import url("../../../assets/css/ql.less");
.main {
  background: #f4f5f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0 80px;
  .title {
    width: 1180px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-bottom: 30px;
    span {
      cursor: pointer;
    }
    span:hover {
      text-decoration: underline;
      color: #0000ee;
    }
  }
  .msgs {
    width: 1180px;
    min-height: 750px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 50px;
    user-select: text;
    .titles {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }
    .time {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin: 22px 0 33px;
      text-align: center;
    }
    .text {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 28px;
    }
    /deep/ a {
      color: #06c;
      text-decoration: underline;
    }
  }
}
.foot {
  height: 64px;
  background: #393d41;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b1a9a9;
}
</style>
